module.exports = [{
      plugin: require('../../../../local-cache/custom/ece6a99d81ee033034768cd0386afeb08988d587012316c712d0e8523080cbc5/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"./src/assets/images/logo-icon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c819da043ce067bdc366597e2aa97ff6"},
    },{
      plugin: require('../../../../local-cache/custom/ece6a99d81ee033034768cd0386afeb08988d587012316c712d0e8523080cbc5/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-1GG2KC6CE2"],"pluginConfig":{"respectDNT":true,"head":false,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0},"gtagConfig":{}},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../../local-cache/custom/ece6a99d81ee033034768cd0386afeb08988d587012316c712d0e8523080cbc5/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
